import styled from "styled-components"


const Title = styled.p`
    font-size: 36px;
    font-weight: 700;
    color: black;
    opacity: 70%;

@media (max-width:  768px) {
    font-size: 20px;
    text-align: left !important;
    width: calc( 100vw - 2rem);
}
`
const Container = styled.div`
width: 100%;
display: flex;
justify-content: center;
align-items: center;
background-color: white;
background-position: 50% 20%;
border-bottom-width: 20px;
background-repeat: no-repeat;
background-size: cover;
border-color: white;
border-top-width: 20px;
border-left: 0;
border-radius: 0;
border-style: solid;
box-shadow: 1px 1px 10px 10px rgb(0,0,0,0.1);

`

const ContainerWidth = styled.div`
width: 100%;
height: 100%;
min-height: 20rem;
padding-block: calc(1rem);
text-align: center;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
color: black;
`

const Content = styled.p`
max-width: 1116px;
opacity: 80%;
font-weight: 600;
opacity: 60%;
font-size: 18px;
text-align: justify;
width: calc( 100vw - 2rem);

@media (max-width:768px) {
    font-size: 16px;
    text-align: left !important;
    
}


`
const ContentTitle = styled.span`
justify-content: center;
display: flex;

h1{
    color: white;
    text-align: center;
}


`

export default function Checkup() {
    return (<Container>

        <ContainerWidth >
            <ContentTitle>

                <Title>
                    Você já fez seu Check-up esse ano?
                </Title>
            </ContentTitle>

            <Content>
                Quando se trata do seu check-up, os meus 14 anos de experiência de Consultório, juntamente com minha atuação como professor na faculdade de medicina Pequeno Principe, fazem toda a diferença. Minha ampla bagagem profissional permite que eu esteja atualizado com as últimas pesquisas, avanços médicos e práticas clínicas mais eficazes.
                <br />
                <br />
                Além disso, minha reputação por uma abordagem humana e compassiva é um aspecto crucial na escolha de um médico para o seu check-up. Eu valorizo cada paciente como um indivíduo único e me esforço para compreender suas necessidades, preocupações e objetivos de saúde. Eu estabeleço uma relação de confiança, oferecendo um ambiente acolhedor e confortável durante a consulta, venha fazer seu check-up comigo.
            </Content>

        </ContainerWidth>


    </Container>)
}