import styled from "styled-components";
import Title from "./Title";
import { Config } from "../config/Config";

const OutCard = styled.div`
  display: flex;
  justify-content: center;
  gap: 3rem;
  margin-inline: 1rem;
  max-width: 1116px;

  @media (max-width: 620px) {
    display: block;
    
  }
`;
const BiographyCard = styled.div`
  display: block;
`;
const ProfileCard = styled.div`
  display: flex;
  flex-direction: column;
  
  a{
    text-decoration: none;
  }

  img {
    margin-block: 1rem;
    height: 20rem;
    object-fit: cover;
    border-radius: 1rem;
    box-shadow: 1px 1px 10px rgb(0, 0, 0, 0.5);

    @media (max-width: 620px) {
      height: 34rem;
    }
    @media (max-width: 480px) {
      height: 28rem;
    }
    @media (max-width: 390px) {
      height: 24rem;
    }
  }
  div {
    margin-block: 0.5rem;
    display: flex;
    align-items: center;
    gap: 1rem;
    border-radius: 1rem;
    padding-inline: 1rem;
    -webkit-transition: 0.3s;
    cursor: pointer;
    color: white;
    p {
      font-weight: 600;
      max-width: 20rem;
    }

    &:hover {
      background-color: black;
      color: white;
    }
  }
`;

const getIWhatsappSocial = Config.socials.find(fs => fs.name === "WhatsApp")

export default function Aboutme(props = { data: Object }) {
  const { name, profileimgid, biography } = props.data;
  return (
    <div>
      <Title>Sobre mim</Title>
      <OutCard>
        <ProfileCard>
          <img src={"img/gallery/" + profileimgid} alt="profile-card" loading="lazy" />
          <a href={getIWhatsappSocial.link} target="_blank" rel="noreferrer">

            <div

              style={{ backgroundImage: getIWhatsappSocial.bgimg }}
            >
              {getIWhatsappSocial.icon}
              <p>{getIWhatsappSocial.name}</p>
            </div>
          </a>

        </ProfileCard>

        <BiographyCard>
          <h3>{name}</h3>
          {biography.map((bio, i) => (
            <p key={"bio_" + i}>{bio}</p>
          ))}
        </BiographyCard>
      </OutCard>
    </div>
  );
}
