import styled from "styled-components";

const Container = styled.div`
  max-width: 1116px;
  width: 100%;
  z-index: 99;
  margin: 0 1rem;
`;
const Section = styled.div`
  background-color: #3fb498;
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 2rem 0; */
  padding-bottom: 3rem;
  width: 100%;
  position: relative;
  overflow: hidden;
`;

const BgImg = styled.img`
  position: absolute;
  inset: 0;
  mix-blend-mode: darken;
  opacity: 30%;
`;

const TitleText = styled.span`
  font-size: 20px;
  color: white;
  font-weight: bold;

  @media (max-width: 620px) {
    font-size: 18px;
  }
`;
const Text = styled.p`
  font-size: 16px;
  color: white;
  opacity: 100%;
  @media (max-width: 620px) {
    font-size: 16px;
  }
`;

const Header = styled.div`
  display: flex;
  gap: 12px;
  padding: 32px 0;

  @media (max-width: 620px) {
    gap: 6px;
    row-gap: 0;
    flex-wrap: wrap;
    img {
      max-width: 32px;
    }
  }

  span,
  p {
    align-self: center;
  }
`;
const Divisor = styled.div`
  min-height: 100%;
  background-color: #fff;
  width: 1px;

  @media (max-width: 620px) {
    display: none;
  }
`;

const Content = styled.div`
  display: flex;
  gap: 12px;
  justify-content: space-between;

  @media (max-width: 620px) {
    flex-direction: column;
  }
`;
const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 620px;
`;
const TextContent = styled.div`
  display: flex;
  gap: 10px;
  padding: 8px 0;
  flex-direction: column;
  align-items: start;

  @media (max-width: 620px) {
    p {
      text-align: justify;
    }
  }
`;

const SubTextHeader = styled(Text)`
  @media (max-width: 620px) {
    width: 100%;
    margin: 0;
    padding-left: 40px;
  }
`;

const ImgBanner = styled.img`
  object-fit: contain;
  border-radius: 16px;
  height: fit-content;
  max-width: 450px;
  box-shadow: 8px 8px 8px 8px rgb(0, 0, 0, 0.08);

  @media (max-width: 920px) {
    max-width: 350px;
  }
  @media (max-width: 768px) {
    max-width: 250px;
  }

  @media (max-width: 620px) {
    max-width: 100%;
  }
`;

export default function IntegativeMedicine() {
  return (
    <Section>
      <BgImg src={"/background/marmore.jpg"} />
      <Container>
        <Header>
          <img src={"/icon/medical-health.svg"} alt="icone medico" />
          <TitleText>Medicina integrativa</TitleText>
          <Divisor />
          <SubTextHeader>protocolos da medicina integrativa</SubTextHeader>
        </Header>
        <Content>
          <TextContainer>
            <TextContent>
              <TitleText>Emagrecimento Consciente</TitleText>
              <Text>
                O protocolo de Emagrecimento Consciente é um programa de 10
                semanas focado na mudança de hábitos de vida para promover uma
                perda de peso sustentável e saudável. Este programa abrange
                orientações nutricionais, planejamento de atividades físicas e
                técnicas de mindfulness, visando transformar a relação do
                paciente com a comida e o exercício. Ao longo das 10 semanas, o
                paciente receberá suporte contínuo para alcançar seus objetivos
                de emagrecimento de forma consciente e equilibrada.
              </Text>
            </TextContent>
            <TextContent>
              <TitleText>Acompanhamento Fitness Personalizado</TitleText>
              <Text>
                O Acompanhamento Fitness Personalizado é ideal para quem deseja
                melhorar seus resultados no fitness. Este protocolo inclui uma
                avaliação inicial detalhada, seguida de um plano personalizado
                que se alinha aos objetivos individuais do paciente. O Dr.
                Henrique Stachon oferece orientação contínua e ajustes no
                programa conforme necessário, garantindo que cada paciente
                maximize seu potencial e alcance resultados significativos e
                duradouros no ganho de massa magra e performance.
              </Text>
            </TextContent>
            <TextContent>
              <TitleText>Saúde 50+</TitleText>
              <Text>
                O protocolo Saúde 50+ é dedicado a promover o bem-estar e a
                qualidade de vida de pacientes com mais de 50 anos. Este
                programa inclui avaliações abrangentes de saúde, planejamento de
                atividades físicas adaptadas, e orientações sobre nutrição e
                suplementação. O objetivo é apoiar um envelhecimento saudável,
                focando na prevenção de doenças, na manutenção da vitalidade e
                na melhoria da funcionalidade física e mental. Com um
                acompanhamento personalizado, o Dr. Henrique Stachon ajuda cada
                paciente a viver de forma ativa e saudável após os 50 anos.
              </Text>
            </TextContent>
          </TextContainer>

          <ImgBanner src={"img/medical.png"} alt="imagem de medico" />
        </Content>
      </Container>
    </Section>
  );
}
