import styled from "styled-components";

const Container = styled.div`
  max-width: 1116px;
  width: 100%;
  z-index: 99;
  margin: 0 1rem;

  display: flex;
  flex-direction: column;
  gap: 1rem;
  @media (max-width: 860px) {
    flex-direction: column;
  }
`;
const Section = styled.div`
  background-color: #dfbfa5;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding-bottom: 3rem;
  width: 100%;
  position: relative;
  overflow: hidden;
  padding-inline: 1rem;

  min-height: 800px;
  padding-block: 2rem;

  @media (max-width: 512px) {
    min-height: 420px;
  }

  &::after {
    content: "";
    position: absolute;
    inset: 0;
    z-index: 50;
    background-color: rgb(0, 0, 0, 0.4);
  }
`;

const Title = styled.span`
  font-size: 96px;
  font-weight: bold;

  line-height: 90%;

  @media (max-width: 1060px) {
    font-size: 72px;
  }
  @media (max-width: 512px) {
    font-size: 48px;
  }
  @media (max-width: 420px) {
    font-size: 32px;
  }

  b {
    color: #ff6f76;
  }
`;

const Text = styled.span`
  font-size: 24px;
  b {
    font-size: 32px;
    font-weight: bold;
  }

  @media (max-width: 1060px) {
    font-size: 16px;

    b {
      font-size: 18px;
    }
  }
`;

const Filter = styled.div`
  position: absolute;

  inset: 0;
  background: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(135, 105, 77, 1) 90% 50%
    ),
    linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, rgba(135, 105, 77, 1) 90%);
  z-index: 10;
  backdrop-filter: blur(3px);
`;

const Content = styled.div`
  color: white;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const Button = styled.a`
  padding: 20px;
  background-color: #3fb498;
  width: 50%;
  text-align: center;
  border-radius: 8px;
  font-size: 20px;
  font-weight: bold;
  align-self: flex-end;
  color: white;
  text-decoration: none;

  @media (max-width: 860px) {
    width: 100%;
    max-width: 100%;
    padding-inline: 0;
  }
  @media (max-width: 512px) {
    width: 100%;
    max-width: 100%;
    padding-block: 12px;
    font-size: 16px;
  }
`;

const Icon = styled.img`
  max-width: 150px;
  height: fit-content;

  @media (max-width: 1060px) {
    max-width: 70px;
  }

  @media (max-width: 768px) {
    max-width: 60px;
  }

  @media (max-width: 512px) {
    max-width: 50px;
  }
`;

const BgImg = styled.img`
  position: absolute;
  /* width: 100%; */
  /* max-width: 900px; */
  max-height: 100%;
  inset: 0;
  /* mix-blend-mode: darken; */
  object-fit: fill;
  margin: auto;
  opacity: 100%;
`;

const Video = styled.iframe`
  border: none;
  border: 3px solid #3fb498;
  padding: 0;
  min-height: 480px;
  width: 270px;
`;

const TextContainer = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: stretch;
`;
const VideoContainer = styled.div`
  display: flex;
  gap: 2rem;
  justify-content: space-around;

  @media (max-width: 620px) {
    flex-direction: column;
    align-items: center;
  }
`;

const VideoContent = styled.div`
  min-height: 100%;
  display: flex;
  gap: 1rem;
  max-width: 50%;

  @media (max-width: 980px) {
    flex-direction: column-reverse;
    max-width: min-content;
  }
`;
const VideoContentText = styled.div`
  color: white;
  padding-top: 125px;

  @media (max-width: 980px) {
    padding-top: 0;
  }
`;
const VideoTitle = styled.span`
  font-size: 24px;
  font-weight: 600;
`;
const VideoDescription = styled.p`
  margin: 0;
  padding: 0;
  font-size: 16px;
`;
export default function Nails({ data }) {
  return (
    <Section>
      <BgImg src={"/img/nail.jpg"} />
      <Filter />
      <Container>
        <TextContainer>
          <Icon src="/icon/nail.svg" alt="icone de unha" />
          <Content>
            <Title>
              Sofre com <b>unhas encravadas?</b>
            </Title>
            <Text>
              <b>Dr. Henrique Stachon</b> oferece o moderno tratamento a laser{" "}
              <b>rápido, seguro e eficaz. </b>Proporcione <b>alívio imediato</b>{" "}
              e elimine a dor e inflamação sem complicações. Procedimento quase
              indolor com <b>recuperação rápida.</b>
            </Text>

            <Button href={data.calls.whatsapp.link} target="_blank">
              Agende sua consulta
            </Button>
          </Content>
        </TextContainer>

        <VideoContainer>
          <VideoContent>
            <Video src={"/videos/nails.mp4"} title="video unha encravada" />
            <VideoContentText>
              <VideoTitle>Unhas encravadas</VideoTitle>
              <VideoDescription>
                Unhas encravadas não precisa ser um problema em sua vida.
              </VideoDescription>
            </VideoContentText>
          </VideoContent>
          <VideoContent>
            <Video
              src={"/videos/henrique_nails.mp4"}
              title="video henrique sobre unhas encreavadas"
            />
            <VideoContentText>
              <VideoTitle>Tratamento</VideoTitle>
              <VideoDescription>
                Dr. Henrique Stachon fala sobre o tratamento de unhas encravadas
                com a tecnologia a Laser.
              </VideoDescription>
            </VideoContentText>
          </VideoContent>
        </VideoContainer>
      </Container>
    </Section>
  );
}
