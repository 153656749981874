import styled from "styled-components";
import { GoLocation } from "react-icons/go";
import Title from "./Title";
import { FaInstagram, FaWhatsapp } from "react-icons/fa";
import { Config } from "../config/Config";

const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90vw;
  margin-inline: auto;
  max-width: 70rem;
  iframe {
    margin-inline: auto;
    border: 0;
    height: 30rem;
    width: 100%;

    border-radius: 1rem;
    display: flex;
    justify-content: center;
  }
  div {
    display: flex;
    align-items: center;
    margin-block: 1rem;
    color: rgb(0, 0, 0, 0.8);

    img {
      height: 3rem;
      width: 3rem;
      margin-inline: 1rem;
      cursor: pointer;
    }
  }
`;

const Icon = styled.span`
margin-inline: auto;
text-align: center;
width: 100%;

margin-top: 2rem;

font-weight: 400;
font-size: 18px;

display: flex;
justify-content: center;
align-items: center;
flex-direction: column;

ul{
  display: flex;
  gap: 1rem;

  list-style: none;
  justify-content: center;
  padding: 0;
  border-top: 1px solid black;
  width: 18rem;

  padding-top: 1rem;

  li{


    cursor: pointer;
-webkit-transition: all 300ms;

     &:hover{
      opacity: 60%;
     }

     svg{
      height: 1.5rem;
      width: 1.5rem;
      opacity: 70%  ;
      color: black;
     }
  }

}

`;

const getInstagraSocial = Config.socials.find(fs => fs.name == "Instagram")
const getIWhatsappSocial = Config.socials.find(fs => fs.name == "WhatsApp")

export default function Maps(props = { data: Array }) {
  const { address, maps } = props.data;

  return (
    <div>
      <Title>Local</Title>

      <Card>
        <iframe
          title="local"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3602.854204592759!2d-49.287630098950494!3d-25.44313738956404!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94dce3816a3d1e01%3A0x7205cbf530c7daed!2zQ2zDrW5pY2EgRmVtaSBTYcO6ZGU!5e0!3m2!1spt-BR!2sbr!4v1677790358518!5m2!1spt-BR!2sbr"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>

        <Icon>

          (41) 99159-0603
          <br />
          Av. Visconde de Guarapuava, 4628
          <br />
          9° andar, sala 908,
          <br />
          Edificio DOC Castelo do Batel
          <br />
          Curitiba • PR
          <br />
          Conheca a clinica:
          <ul>

            <a href={getInstagraSocial.link} target="_blank">
              <li>
                {getInstagraSocial.icon}
              </li>
            </a>
            <a href={getIWhatsappSocial.link} target="_blank">
              <li>
                {getIWhatsappSocial.icon}
              </li>
            </a>

          </ul>

        </Icon>
        {/* <div
          onClick={(e) => {
            window.location.href = maps;
          }}
          style={{ cursor: "pointer" }}
        >
          <img alt="maps-btn" src="/icon/maps.png" /> Clique para obter no maps.
        </div> */}
      </Card>
    </div>
  );
}
