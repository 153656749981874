import styled from "styled-components";
import React from "react";
import Logo from "../components/Logo";
import { Config } from "../config/Config";

const HeaderSC = styled.header`
  min-height: 3rem;
  align-items: center;
  box-shadow: 1px 1px 6px rgb(0, 0, 0, 0.2);
  display: block;
  align-content: center;
  background-color: white;
  div,a {
    margin-inline: auto;
    max-width: 90rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

const LogoContent = styled.div`
  text-align: center;
  img {
    max-width: 5rem;
  }
`;
const BtnContent = styled.span`
   background-color: var(--green-color);
  color: white;
  border: none;
  height: 2rem;
  padding-inline: 1rem;
  border-radius: 0.3em;
  align-content: center;
  align-items: center;
  display: inline-flex;
  justify-content: center;
  gap: 0.5rem;
  cursor: pointer;
  -webkit-transition: 0.3s;
`;
const Nav = styled.nav`
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  text-decoration: none;
  list-style-type: none;
  text-align: center;
  gap: 0.25rem;
  li {
    a {
      text-decoration: none;
      color: black;
      opacity: 80%;
    }
  }
`;
const Btn = styled.a`
  display: flex;
  justify-content: center;
  text-decoration: none;

  span {
    &::after {
      content: "Agende sua consulta";
      white-space: nowrap;
    }
    margin-left: auto;
    margin-inline: 1rem;

    @media (max-width: 340px) {
      &::after {
        content: "Consultar";
      }
    }
  }
`;



export default function Header(props = { mobile: String }) {
  return (
    <HeaderSC>
      <div>
        <LogoContent>
          <Logo />
        </LogoContent>

        <Nav
          style={{
            display: props.mobile === "block" ? "none" : "flex",
          }}
        >
          <li>
            <a href="#actuator">Área de atuação</a>
          </li>
          <li>
            <a href="#aboutme">Sobre mim</a>
          </li>
          <li>
            <a href="#integative-medicine">Protocolo de tratamento</a>
          </li>
          <li>
            <a href="#local">Local</a>
          </li>
          <li>
            <a href={Config.calls.whatsapp.link} target="_blank" rel="noreferrer">Entre em contato</a>
          </li>
        </Nav>
        <Btn href={Config.calls.whatsapp.link} target="_blank">
          <BtnContent></BtnContent>
        </Btn>
      </div>
    </HeaderSC>
  );
}
