import { useEffect, useState } from "react";
import styled from "styled-components";
import responsive from "../functions/Responsive";
const Card = styled.div`
  box-shadow: 1px 1px 6px rgb(0, 0, 0, 0.2);
  align-items: center;
  margin: auto;
  background-color: white;
`;

const Content = styled.div`
  padding: 2rem;
  width: 20rem;
  height: 15rem;

  @media (max-width: 380px) {
    width: 100vw;
    padding: 0;
  }

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h3,
  b,
  p {
    width: 100%;
    text-align: center;
  }

  h3 {
    margin: 0;
    display: block;
    position: relative;
    width: fit-content;
    &::after {
      content: "";
      width: 25%;
      height: 4px;
      background-color: #3fb498;
      position: absolute;
      bottom: -8px;
      left: 0;
    }
  }
  p {
  }
`;
const Socials = styled.div`
  margin-top: 1rem;
  gap: 5px;
  display: flex;
  justify-content: center;

  a {
    color: black;

    cursor: pointer;
    -webkit-transition: 0.3s;
    &:hover {
      opacity: 50%;
    }
    svg {
      margin-inline: 0.2rem;
      width: 1.3rem;
      height: 1.3rem;
      color: #3fb498;
    }
  }
`;
const ImgCard = styled.div`
  padding: 1rem;
  width: 22rem;
  height: 20rem;
  background-size: cover;
  background-position: 0rem -4rem;

  @media (max-width: 800px) {
    width: unset;
  }
`;

export default function CardUser(props = { data: Object }) {
  const [resp, setResp] = useState("");
  useEffect(() => {
    responsive({ min: 800, setState: setResp });
  }, []);
  const { socials, name, description, cardimgid, crm, rqe } = props.data;
  return (
    <Card style={{ display: resp }}>
      <Content>
        <h3>{name}</h3>
        <p>{description}</p>
        <b>{crm}</b>
        <b>{rqe}</b>

        <Socials>
          {socials.map((scl, i) => (
            <a key={i} href={scl.link}>
              {scl.icon}
            </a>
          ))}
        </Socials>
      </Content>
      <ImgCard
        style={{ backgroundImage: `url('/img/gallery/${cardimgid}')` }}
      ></ImgCard>
    </Card>
  );
}
